<template>
  <div class="recipe-form fill-height">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter une fiche recette</h3>

      <form ref="formel" @submit.prevent="onSubmit" method="POST">
        <div class="mb-s">
          <h5 class="mb-xxs">Titre de la recette *</h5>
          <FormInput name="title" :required="true" />
        </div>

        <div class="infos">
          <div class="mb-s">
            <h5 class="mb-xxs">Nombre de personnes *</h5>
            <FormInput name="people" :required="true" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Durée de préparation (min) *</h5>
            <FormInput name="preptime" :required="true" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Durée de cuisson (min) *</h5>
            <FormInput name="cooking" :required="true" />
          </div>
        </div>

        <div class="m-s">
          <h5 class="mb-xxs">Matériels</h5>

          <label
            class="piece mb-xxs"
            v-for="(item, index) in materials"
            :key="item"
          >
            <div>Matériel {{ index + 1 }}</div>
            <input type="text" :name="`material_${index + 1}`" />
          </label>

          <a class="add" @click.prevent="addMaterial" v-if="materials < 50">
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter un matériel</span>
          </a>
        </div>

        <div class="m-s">
          <h5 class="mb-xxs">Ingrédients *</h5>

          <label
            class="piece mb-xxs"
            v-for="(item, index) in ingredients"
            :key="item"
          >
            <div>Ingrédient {{ index + 1 }}</div>
            <input
              type="text"
              :name="`ingredient_${index + 1}`"
              :required="index === 0"
            />
          </label>

          <a
            class="add"
            @click.prevent="addIngredients"
            v-if="ingredients < 50"
          >
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter un ingrédient</span>
          </a>
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Préparation *</h5>
          <textarea name="preparation" required></textarea>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Service</h5>

          <div class="list-checks">
            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input
                name="service"
                value="a-servir-tout-de-suite"
                type="checkbox"
              />
              <span>A servir tout de suite</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input
                name="service"
                value="preparation-possible-a-lavance"
                type="checkbox"
              />
              <span>Préparation possible à l’avance</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input
                name="service"
                value="preparation-possible-la-veille"
                type="checkbox"
              />
              <span>Préparation possible la veille</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="service" value="autre" type="checkbox" />
              <span>Autre</span>
            </label>
          </div>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Lieu de Préparation</h5>

          <div class="list-checks">
            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="place[]" value="sous-tente" type="checkbox" />
              <span>Sous-tente</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="place[]" value="sous-batiment" type="checkbox" />
              <span>Sous bâtiment</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="place[]" value="autre" type="checkbox" />
              <span>Autre</span>
            </label>
          </div>
        </label>

        <label class="m-s">
          <h5 class="mb-xxs">Type de repas *</h5>

          <div class="list-checks">
            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input
                name="type[]"
                value="entree-petit-dejeuner"
                type="checkbox"
              />
              <span>Entrée/petit déjeuner</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="type[]" value="plat" type="checkbox" />
              <span>Plat</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="type[]" value="dessert-gouter" type="checkbox" />
              <span>Dessert/Goûter</span>
            </label>

            <label class="input-checkbox mb-xxs">
              <SvgSprite symbol="icons-ok" size="20 15" />
              <input name="type[]" value="autre" type="checkbox" />
              <span>Autre</span>
            </label>
          </div>
        </label>

        <div class="mb-s">
          <h5 class="mb-xxs">Prix (Prix bas = €, moyen = €€, cher = €€€)</h5>
          <FormInput name="price" />
        </div>

        <div class="photos m-s">
          <h5 class="mb-xxs">Photo</h5>
          <Upload
            ref="uploadPhotos"
            name="photos[]"
            icon="image"
            :max="3"
            :options="{
              allowFileTypeValidation: true,
              acceptedFileTypes: ['image/*'],
              allowFileSizeValidation: true,
              maxFileSize: '5MB',
            }"
          />
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ta recette est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ta recette a bien été envoyée. Après vérification, elle sera publiée
          et disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive } from 'vue'
import { create } from '@/guides/endpoints/recipe'
import { useRoute } from 'vue-router'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    Upload,
  },
  setup() {
    const route = useRoute()
    const category = computed(() => route?.params?.subcat)
    console.log('😺 SubCat', category.value)
    const showAlert = ref(false)
    const materials = ref(3)
    const ingredients = ref(3)
    const variant = ref('')
    const uploadPhotos = ref()

    const addMaterial = () => {
      materials.value += 1
    }

    const addIngredients = () => {
      ingredients.value += 1
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('category', category.value as string)

      const photos = uploadPhotos.value.getFiles()
      data.delete('photos[]')

      if (photos.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      variant,
      materials,
      addMaterial,
      ingredients,
      addIngredients,
      showAlert,
      uploadPhotos,
    }
  },
})
</script>

<style lang="scss" scoped>
.recipe-form {
  @include form-base;

  background: $c-white;
}

@include mq(l) {
  .infos {
    display: flex;
    justify-content: space-between;

    > * {
      flex-basis: 30%;
      margin-bottom: 0;
    }
  }

  .variants {
    display: flex;

    > * {
      flex-basis: 50%;
    }

    .variant-desc {
      padding-top: 0;
      border-top: 0;
    }
  }

  .list-checks {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-basis: 50%;
      margin: 7px 0;
    }
  }
}
</style>
